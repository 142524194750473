import styled from "styled-components";

const Container = styled.div`

    margin-inline-start: 1em;
    margin-inline-end: 1em;
    margin-block-end: 2em;
`


export default Container;